import React from 'react';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gold: 0,
      unidades: 0
  };
  }
  componentDidMount(){
    this.myInterval = setInterval(()=>{
      this.setState({
        gold: this.state.gold+30
      })
     
    },1000);
  }

   creaUnidad=()=>{
      this.setState(
        {
          unidades: this.state.unidades+1,
          gold: this.state.gold-40
      });
  }
  render(){
    return (
      <div className="App">
        <header className="App-header">
          <p>Oro: <span>{this.state.gold.toFixed(0)}</span></p>
          <p>Unidades: <span>{this.state.unidades.toFixed(0)}</span></p>
          <button onClick={this.creaUnidad}>Crea unidad</button>
        </header>
      </div>
    );
  }
  
}

export default App;
